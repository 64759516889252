import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  Text,
  InputGroup,
  InputRightElement,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import fetchData from "../../utils/fetchData";
import LoadingOverlay from "../../utils/LoadingOverlay";
import config from "../../config";
import { handleLogout } from '../MainMenu'

type ChangePasswordForm = {
  newPassword: string;
  confirmPassword: string;
};

const validatePassword = (password: string): string | true => {
  const minLength = 8;
  const maxLength = 16;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  if (password.length < minLength) {
    return `A senha deve ter pelo menos ${minLength} caracteres.`;
  }
  if (password.length > maxLength) {
    return `A senha deve ter no máximo ${maxLength} caracteres.`;
  }
  if (!hasUpperCase) {
    return "A senha deve conter pelo menos uma letra maiúscula.";
  }
  if (!hasLowerCase) {
    return "A senha deve conter pelo menos uma letra minúscula.";
  }
  if (!hasNumber) {
    return "A senha deve conter pelo menos um número.";
  }
  if (!hasSpecialChar) {
    return "A senha deve conter pelo menos um caractere especial.";
  }

  return true;
};

const ChangePasswordModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true); // Inicia com o modal aberto
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const toast = useToast();
  const navigate = useNavigate(); // Hook para redirecionar o usuário
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm<ChangePasswordForm>();

  const newPassword = watch("newPassword");

  useEffect(() => {
    setIsOpen(true); // Garante que o modal esteja aberto ao montar o componente
  }, []);

  const onSubmit = async (data: ChangePasswordForm) => {
    LoadingOverlay.addOverlay();
    try {
      const url = `${config.apiUrl}/users`;
      await fetchData(url, "patch", toast, { senha: data.newPassword });

      setIsOpen(false); // Fecha o modal após sucesso
      reset();
      toast({
        title: "Senha alterada com sucesso!",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      handleLogout(navigate, setIsLoggedIn); // Redireciona para login após sucesso
    } finally {
      LoadingOverlay.removeOverlay();
    }
  };

  const handleClose = () => {
    setIsOpen(false); // Fecha o modal
    reset(); // Reseta o formulário
    handleLogout(navigate, setIsLoggedIn); // Redireciona após cancelamento
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} isCentered size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Alterar Senha</ModalHeader>
        <ModalCloseButton onClick={handleClose} />
        <ModalBody display="flex" flexDirection="column" gap={4}>
          <Box p={4} border="1px solid #CBD5E0" borderRadius="md" bg="#F7FAFC">
            <Text fontWeight="bold" mb={2}>Regras para a nova senha:</Text>
            <Text fontSize="sm">- Mínimo de 8 e máximo de 16 caracteres.</Text>
            <Text fontSize="sm">- Pelo menos uma letra maiúscula.</Text>
            <Text fontSize="sm">- Pelo menos uma letra minúscula.</Text>
            <Text fontSize="sm">- Pelo menos um número.</Text>
            <Text fontSize="sm">- Pelo menos um caractere especial (!@#$%^&* etc.).</Text>
          </Box>

          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={!!errors.newPassword} mb={4}>
              <FormLabel>Nova Senha</FormLabel>
              <InputGroup>
                <Controller
                  name="newPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "A nova senha é obrigatória.",
                    validate: validatePassword,
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type={showNewPassword ? "text" : "password"}
                      placeholder="Digite a nova senha"
                    />
                  )}
                />
                <InputRightElement>
                  <IconButton
                    variant="ghost"
                    icon={showNewPassword ? <ViewOffIcon /> : <ViewIcon />}
                    aria-label="Toggle password visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.newPassword && errors.newPassword.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.confirmPassword} mb={4}>
              <FormLabel>Confirme a Nova Senha</FormLabel>
              <InputGroup>
                <Controller
                  name="confirmPassword"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: "A confirmação da senha é obrigatória.",
                    validate: (value) =>
                      value === newPassword || "As senhas não coincidem.",
                  }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder="Confirme a nova senha"
                    />
                  )}
                />
                <InputRightElement>
                  <IconButton
                    variant="ghost"
                    icon={showConfirmPassword ? <ViewOffIcon /> : <ViewIcon />}
                    aria-label="Toggle confirm password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {errors.confirmPassword && errors.confirmPassword.message}
              </FormErrorMessage>
            </FormControl>

            <Button colorScheme="orange" type="submit" width="full" mb={4}>
              Confirmar
            </Button>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" colorScheme="orange" onClick={handleClose} width="full">
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangePasswordModal;
