import React, { useState, useEffect } from "react";

import DataTable from "react-data-table-component";
import { Box, Text, Grid, GridItem, VStack, useDisclosure } from "@chakra-ui/react";

import { Projeto, Documento, accessConvidadosPgs } from "../../types/types"
import ExpandableRow from "./ExpandableRow";
import fetchData from "../../utils/fetchData";
import config from "../../config";
import { useCustomToast } from "../../utils/toastUtils";
import LoadingOverlay from "../../utils/LoadingOverlay";
import SobreDocumento from "../desligados/projetos/SobreDocumento";
import ModelosConstruidos from "../ModelosConstruidos";
import FileUpload from "./FileUpload";
import ProjetoModal from "./ProjetoModal";
import DocumentoModal from "./DocumentoModal";
import { getDocumentButtonConfig, getProjectButtonConfig } from "./buttonConfig";
import DocumentDetails from "./DocumentDetails";
import ProjectDetails from "./ProjectDetails";

interface HierarchicalTableProps {
  Descritor: 0 | 1; // Define o tipo como 0 ou 1
}

const HierarchicalTable: React.FC<HierarchicalTableProps> = ({ Descritor }) => {
  const [data, setData] = useState<Projeto[]>([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  const [projectButtonConfig, setProjectButtonConfig] = useState<any[]>([]);
  const [documentButtonConfig, setDocumentButtonConfig] = useState<any[]>([]);

  const [expandedRow, setExpandedRow] = useState<number | null>(null);
  const [selectedProjectId, setSelectedProjectId] = useState<number | null>(null);
  const [selectedDocumentId, setSelectedDocumentId] = useState<number | null>(null);
  const [isModalOpenSobre, setIsModalOpenSobre] = useState(false);
  const [isGrafosOpen, setIsGrafosOpen] = useState(false);
  const [isFileUpOpen, setIsFileUpOpen] = useState(false);
  const [action, setAction] = useState<"create" | "edit" | "delete">("create");

  const toast = useCustomToast();

  const loadData = async (currentPage: number, currentDescritor: number) => {
    LoadingOverlay.addOverlay();
    if (!loading) {
      setLoading(true);
      const url = `${config.apiUrl}/access/convidadopgs/${currentDescritor}?page=${currentPage}&pageSize=${pageSize}`;
      const response: accessConvidadosPgs | null = await fetchData(url, "get", toast);
      if (response?.data && response?.total !== undefined) {
        setData(response.data);
        setTotal(response.total);
      } else {
        setData([]);
        setTotal(0);
      }
      setLoading(false);
    }
    LoadingOverlay.removeOverlay();
  };

  // Quando o `Descritor` muda
  useEffect(() => {
    setPage(1);
    setSelectedProjectId(null)
    setSelectedDocumentId(null)
    loadData(1, Descritor);
  }, [Descritor]);

  // Quando a página muda
  useEffect(() => {
    loadData(page, Descritor);
  }, [page]);

  useEffect(() => {
    updateProjectButtonConfig();
    updateDocumentButtonConfig();
  }, [selectedProjectId]); // Recalcular ao adicionar ou selecionar um projeto

  useEffect(() => {
    updateDocumentButtonConfig();
  }, [selectedDocumentId]);

  useEffect(() => {
    //console.log("mudou o data ", data )
  }, [data])

  const updateProjectButtonConfig = () => {
    if (data.length > 0 && selectedProjectId !== null) {
      const selectedProject = data.find(
        (projeto) => projeto.id_projeto === selectedProjectId
      );
      setProjectButtonConfig(
        selectedProject
          ? getProjectButtonConfig(
            selectedProject,
            setAction,
            onOpenProjeto,
            setIsGrafosOpen
          )
          : []
      );
    } else {
      setProjectButtonConfig([]);
    }
  }

  const updateDocumentButtonConfig = () => {
    if (data.length > 0) {
      const selectedProject = selectedProjectId
        ? data.find((projeto) => projeto.id_projeto === selectedProjectId)
        : null;

      // Atualiza as configurações de botões do documento
      setDocumentButtonConfig(
        selectedProject
          ? getDocumentButtonConfig(
            selectedProject,
            selectedDocumentId,
            setAction,
            setSelectedProjectId,
            onOpenDocumento,
            setIsFileUpOpen,
            setIsModalOpenSobre
          )
          : []
      );
    } else {
      setDocumentButtonConfig([]);
    }
  };

  const columns = [
    {
      name: "Projetos",
      selector: (row: Projeto) => row.titulo,
      width: "500px", // Define uma largura específica
      //sortable: true, // condição para ordenar 
      cell: (row: Projeto) => (
        <Box
          cursor="pointer"
          p={2}
          borderRadius="md"
          bg={row.id_projeto === selectedProjectId ? "blue.100" : "white"}
          _hover={{
            bg: row.id_projeto === selectedProjectId ? "blue.200" : "gray.100",
          }}
          onClick={() => {
            setExpandedRow(row.id_projeto === expandedRow ? null : row.id_projeto);
            setSelectedProjectId(row.id_projeto);
            setSelectedDocumentId(null);
          }}
        >
          {row.titulo.length > 50 ? `${row.titulo.substring(0, 50)}...` : row.titulo}
        </Box>
      ),
    },
  ];

  //****************************************
  // Controle dos dados do documento
  //****************************************
  const { isOpen: isOpenDocumento, onOpen: onOpenDocumento, onClose: onCloseDocumento } = useDisclosure();
  const removeDocumentoFromProjeto = (projeto: Projeto, documento: Documento) => {
    setSelectedDocumentId(null);
    return {
      ...projeto,
      documentos: projeto.documentos.filter(
        (doc) => doc.id_documento !== documento.id_documento
      ),
    };
  };
  const handleSaveDocumento = async (documento: Documento) => {
    let updatedData;
  
    setData((prevData) => {
      updatedData = prevData.map((projeto) => {
        if (projeto.id_projeto === documento.id_projeto) {
          if (action === "create") {
            // Manipulação inicial, o fetch será tratado fora.
            return {
              ...projeto,
              documentos: [...projeto.documentos, { ...documento }],
            };
          } else if (action === "edit" && documento.id_documento) {
            return {
              ...projeto,
              documentos: projeto.documentos.map((doc) =>
                doc.id_documento === documento.id_documento
                  ? { ...doc, ...documento }
                  : doc
              ),
            };
          } else if (action === "delete" && documento.id_documento) {
            return removeDocumentoFromProjeto(projeto, documento);
          }
        }
        return projeto;
      });  
      return updatedData;
    });
  
    if (action === "create" || action === "edit") {
      let caminho = `${config.apiUrl}/process/classify/${1}/${documento.id_documento}/O`;
      await fetchData(caminho, 'get', toast);
      caminho = `${config.apiUrl}/process/classify/${2}/${documento.id_documento}/O`;
      await fetchData(caminho, 'get', toast);
    }
  
    onCloseDocumento();
  };
  
  const handleDeleteDocumento = () => {
    const idDocumento = selectedDocumentId;
    const idProjeto = selectedProjectId;
    setData((prevData) =>
      prevData.map((projeto) => {
        if (projeto.id_projeto === idProjeto) {
          // Remove o documento correspondente do projeto
          return {
            ...projeto,
            documentos: projeto.documentos.filter(
              (documento) => documento.id_documento !== idDocumento
            ),
          };
        }
        return projeto; // Retorna os projetos inalterados
      })
    );
    onCloseDocumento(); // Fecha o modal de documento
  };
  //****************************************
  // Controle dos dados do projeto
  //****************************************
  const { isOpen: isOpenProjeto, onOpen: onOpenProjeto, onClose: onCloseProjeto } = useDisclosure();
  const handleSaveProjeto = (projeto: Projeto) => {
    if (action === "create") {
      // Criar um novo projeto
      const newProjectId = projeto.id_projeto;
      setData((prevData) => [...prevData, { ...projeto, descritor: Descritor === 1, usuario: true, documentos: [] }]);
      setSelectedProjectId(newProjectId);
      setSelectedDocumentId(null);
    } else if (action === "edit" && selectedProjectId !== null) {
      // Editar projeto existente
      setData((prevData) =>
        prevData.map((item) =>
          item.id_projeto === selectedProjectId ? { ...item, ...projeto } : item
        )
      );
    }
    onCloseProjeto(); // Fecha o modal
  };

  const handleDeleteProjeto = () => {
    loadData(1, Descritor); // Carrega os dados com o novo descritor
    setSelectedProjectId(null);
  };

  //****************************************
  // Fim controle dos dados do projeto
  //****************************************

  const handleDocumentSelect = (doc: Documento) => {
    setSelectedProjectId(doc.id_projeto);
    setSelectedDocumentId(doc.id_documento);
  };

  return (
    <Grid templateColumns="40% 59%" height="calc(100vh - 180px)" gap={4}>
      {/* Primeira parte: DataTable com scroll exclusivo */}
      <GridItem
        border="1px solid"
        borderColor="gray.400"
        p={4}
        mb={8}
        borderRadius="md"
        bg="white"
        h="100%"
      >
        {/* Área com scroll horizontal e vertical */}
        <VStack
          spacing={4}
          align="start"
          overflowY="auto"
          maxHeight="calc(100vh - 240px)" // Altura ajustada
        >
          <Box
            flex="1"
            overflowX="auto" // Permite rolagem horizontal
            overflowY="auto" // Permite rolagem vertical
            borderTop="1px solid #e2e8f0"
            pt={4}
          >
            {/* Cabeçalho fixo */}
            <Text fontSize="2xl" mb={4}>
              {Descritor === 0 ? "Documentos a analisar" : "Descritores"}
            </Text>
            {!loading && (
              <DataTable
                key={`${page}`}
                columns={columns}
                data={data}
                expandableRows
                expandableRowExpanded={(row) => row.id_projeto === expandedRow}
                onRowExpandToggled={(expanded, row) => {
                  setExpandedRow(expanded ? row.id_projeto : null);
                  setSelectedProjectId(expanded ? row.id_projeto : null);
                  setSelectedDocumentId(null);
                }}
                expandableRowsComponent={(props) => (
                  <ExpandableRow
                    documentos={props.data.documentos}
                    onDocumentSelect={handleDocumentSelect}
                    selectedDocumentId={selectedDocumentId}
                  />
                )}
                pagination
                paginationComponentOptions={{
                  rowsPerPageText: "Linhas por página", // Texto personalizado
                  rangeSeparatorText: "de",             // Separador de páginas
                  selectAllRowsItem: false,             // Se você quiser habilitar "Todos os itens"
                  selectAllRowsItemText: "Todos",       // Texto para "Todos os itens"
                }}
                paginationDefaultPage={page}
                paginationTotalRows={total} // Certifique-se de que 'total' é o número correto de registros
                paginationPerPage={pageSize} // Número de registros por página
                paginationServer // Ativa paginação controlada pelo servidor
                onChangePage={(newPage) => {
                  if (newPage !== page) {
                    setPage(newPage); // Altera a página
                  }
                }}
                theme="solarized"
              />
            )}
          </Box>
        </VStack>
      </GridItem>

      {/* Segunda parte: Novo Box sem scroll */}
      <GridItem
        border="1px solid"
        borderColor="gray.400"
        p={4}
        mb={8}
        borderRadius="md"
        bg="white"
        h="100%"
      >
        <VStack
          spacing={4}
          align="start"
          overflowY="auto"
          maxHeight="calc(100vh - 240px)" // Altura ajustada
        >
          <ProjectDetails
            data={data}
            selectedProjectId={selectedProjectId}
            projectButtonConfig={projectButtonConfig}
            documentButtonConfig={documentButtonConfig}
            onAddProject={() => {
              setAction("create");
              setSelectedProjectId(null);
              onOpenProjeto();
            }}
          />
          <DocumentDetails
            data={data}
            selectedDocumentId={selectedDocumentId}
            Descritor={Descritor}
          />
        </VStack>
        {/* Carrega os grafos do documento */}
        {isModalOpenSobre && selectedDocumentId && (
          <SobreDocumento
            id_documento={selectedDocumentId}
            titulo={data
              .flatMap((projeto) => projeto.documentos)
              .find((doc) => doc.id_documento === selectedDocumentId)?.titulo || ""}
            isOpen={isModalOpenSobre}
            onClose={() => setIsModalOpenSobre(false)}
          />
        )}
        {/* Carrega os modelos para o projeto */}
        {isGrafosOpen && selectedProjectId && (
          <ModelosConstruidos
            isOpen={isGrafosOpen}
            onClose={() => setIsGrafosOpen(false)}
            id_projeto={selectedProjectId}
          />
        )}
        {/* Carrega arquivos do excell */}
        {isFileUpOpen && selectedProjectId && (
          <FileUpload
            isOpen={isFileUpOpen}
            onClose={() => {
              setIsFileUpOpen(false)
              loadData(1, Descritor);
            }}
            id_projeto={selectedProjectId}
          />
          
        )}
        {/* Carrega o modal de edição do projeto */}
        {isOpenProjeto && (
          <ProjetoModal
            isOpen={isOpenProjeto}
            onClose={onCloseProjeto}
            onSave={handleSaveProjeto}
            onDelete={handleDeleteProjeto}
            projeto={
              action === "create"
                ? {
                  titulo: "",
                  ano_inicial: new Date().getFullYear(),
                  ano_final: new Date().getFullYear(),
                  descritor: Descritor === 1,
                  usuario: false,
                  id_projeto: 0,
                  documentos: []
                }
                : data.find((proj) => proj.id_projeto === selectedProjectId) || null
            }
            action={action}
          />
        )}
        {/* Carrega o modal de edição do documento */}
        {isOpenDocumento && (
          <DocumentoModal
            isOpen={isOpenDocumento}
            onClose={onCloseDocumento}
            onSave={handleSaveDocumento}
            onDelete={handleDeleteDocumento}
            documento={
              action === "create"
                ? {
                  id_documento: 0, // Gera um ID único
                  id_projeto: selectedProjectId, // Garante que id_projeto seja um número
                  titulo: "",
                  discente: "",
                  orientador: "",
                  resumo: "",
                  graphData: {},
                  anoPublicacao: new Date().getFullYear(),
                  palavraChave: [],
                }
                : data
                  .find((projeto) => projeto.id_projeto === selectedProjectId)
                  ?.documentos.find((doc) => doc.id_documento === selectedDocumentId) || null
            }
            action={action}
            descritor={Descritor == 1}
          />
        )}
      </GridItem>
    </Grid >
  );
};

export default HierarchicalTable;
