// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import Home from './pages/Home';
import DadosPessoais from './pages/DadosPessoais';
import Layout from './components/layoutPage/Layout';
import PrivateRoute from './routes/PrivateRoute';
import Login from './pages/Login';
import HierarchicalTable from './components/project/HierarchicalTable';
import ChangePasswordModal from './components/user/ChangePasswordModal';

const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/changepassword" element={
              <PrivateRoute>
                <ChangePasswordModal />
              </PrivateRoute>
            }
            />
            <Route path="/dados-pessoais" element={
              <PrivateRoute>
                <DadosPessoais />
              </PrivateRoute>
            } />
            
            
            <Route path="/descritores" element={
              <PrivateRoute>
                <HierarchicalTable Descritor={1} />
              </PrivateRoute>
            }
            />
            <Route path="/projetos" element={
              <PrivateRoute>
                <HierarchicalTable Descritor={0} />
              </PrivateRoute>
            }
            />
            
            {/* <Route path="/relatorios" element={
              // <PrivateRoute>
              //   <Grafico />
              // </PrivateRoute>
              <ChakraProvider>
                
              </ChakraProvider>
            }
            /> */}
            {/* Adicione outras rotas conforme necessário */}
          </Routes>

        </Layout>
      </Router>
    </ChakraProvider>
  );
};

export default App;
